/**
 * axios拦截器
 */


import axios from 'axios'
import Cookies from 'js-cookie'
import {
    Notification
} from 'element-ui';
import vueVideoPlayer from 'vue-video-player';

// 配置默认的host,假如你的API host是：http://api.htmlx.club
// axios.defaults.baseURL = 'http://192.169.160.18:8000'

// 添加请求拦截器
// const request = axios.create({
//     baseURL: 'https://www.jass1688.com',
//     timeout: 5000*3,
// })
const request = axios.create({
    baseURL: 'http://192.168.1.175:8000',
    timeout: 5000*3,
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // console.log(config)

    const token = Cookies.get("token")
 
    /**token在请求头中 */
    if (token) {
        config.headers.Authorization = `Bearer${token}`,
            config.headers.token = token
        //   config.headers.Content-Type="application/x-www-form-urlencoded "
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
var that = this
request.interceptors.response.use(function (response) {
    switch (response.data.code) {
        case 5001:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5002:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
            break;
        case 5003:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
            break;
        case 5004:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return window.location.href = "/login"
            break;
        case 5005:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5006:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5007:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5008:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5009:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5010:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5011:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            Cookies.remove("token")
            Cookies.remove('user');
            Cookies.remove('userRoleId');
            window.location.href = "/login"
            return response 
            break;

        case 5012:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;

        case 5013:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;

        case 5014:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5015:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5016:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5017:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5018:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5019:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5020:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5021:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5022:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5023:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5024:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5025:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5026:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}，请重新添加!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5027:
            Notification({
                title: '提示',
                message: `连接出错:${response.data.message}!`,
                type: 'warning',
                position: 'top-left'
            })
            return response
            break;
        case 5028:
            err.message = 'HTTP版本不受支持:505。';
            break;
        default:
            return response
    }
    // return response 

}, function (err) { 
    if (err && err.response) {
        switch (err.response.status) {
            case 404:
                Notification({
                    title: '提示',
                    message: '网络连接故障',
                    type: 'warning',
                    position: 'top-left'
                })
                return window.location.href = "/login"
                break;
            default:
                Notification({
                    title: '提示',
                    message: `连接出错(${err.response.status})!`,
                    type: 'warning',
                    position: 'top-left'
                })
        }
    } else {
         Notification({
             title: '提示',
             message: `连接出错(${err})!`,
             type: 'warning',
             position: 'top-left'
         })
        }
    return Promise.reject(err);
});
// 添加响应拦截器
// request.interceptors.response.use(function (response) {

//     return response;
// }, function (error) {
//     if (err && err.response) {
//         switch (err.response.status) {
//             case 5001:
//                 err.message = '登录请求参数出错';
//                 break;
//             case 5002:
//                 return history.push('/login');
//                 break;
//             case 5003:
//                 err.message = '拒绝访问(403)';
//                 break;
//             case 5004:
//                 err.message = '请求出错(404)';
//                 break;
//             case 5005:
//                 err.message = '请求超时(408)';
//                 break;
//             case 5006:
//                 err.message = '服务器错误(500)';
//                 break;
//             case 5007:
//                 err.message = '服务未实现(501)';
//                 break;
//             case 5008:
//                 err.message = '网络错误(502)';
//                 break;
//             case 5009:
//                 err.message = '服务不可用(503)';
//                 break;
//             case 5010:
//                 err.message = '网络超时(504)';
//                 break;
//             case 5011:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5012:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5013:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5014:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5015:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5016:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5017:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5018:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5019:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5020:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5021:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5022:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5023:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5024:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5025:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5026:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5027:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             case 5028:
//                 err.message = 'HTTP版本不受支持(505)';
//                 break;
//             default:
//                 err.message = `连接出错(${err.response.status})!`;
//         }
//     } else {
//         err.message = '连接服务器失败!'
//     }
//     message.error(err.message);
//     return Promise.reject(error);
// });
export default request //3.