/*
 * @LastEditors: 刘森
 * @Date: 2023-06-01 15:37:31
 * @LastEditTime: 2023-06-15 18:22:17
 * @当前页面: 首页
 */
/**
 * 请求接口
 */
const url = "https://www.jass1688.com"
// const url = 'http://127.0.0.1:8000'
export default {
    login: url + "/login/",
    user: url + "/common/index/",
    history: url + "/common/history/",
    equipmentlog: url + "/common/log/",
    statistic: url + "/common/statistic/",
    systemlog: url + "/manager/sysLog/",
    company: url + "/manager/company/",
    equipment: url + "/manager/device/",
    exchangeqm: url + "/manager/bulk/",
    liveplay: url + "/livedatum/live/",
    histogram: url + "/common/onlinetime/",
    piechart: url + "/common/alarmcount/",
    linechart: url + "/common/alarmtime/",
    addeqmtype: url + "/manager/version/",
    myeqm: url + "/common/mydevices/",
    edituser: url + "/common/update/",
    savevideo: url + "/livedatum/savevideo/",
    checkvideo: url + "/livedatum/vod/",
    closevide: url + "/livedatum/closelive/",
    looprequest: url + "/livedatum/live/",
    payfor: url + "/common/siminfo/",
    confirmpay: url + "/common/wxpay/",
    payloop: url + "/common/payresult/",
    order: url + "/manager/orderinfo/"
}